import React, { Fragment, useRef } from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";

import styles from "../components/base.module.scss";

import Haikus from "../components/Haikus.js";

class Syllables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: null
    };
  }

  setHover = index => {
    this.setState({ hover: index });
  };

  clearHover = index => {
    if (this.state.hover === index) this.setState({ hover: null });
  };

  render() {
    const { contents } = this.props;
    const { hover } = this.state;
    return (
      <p className={styles.syllables}>
        {contents.map((syllable, index) => (
          <span
            className={styles.syllable}
            onMouseEnter={() => this.setHover(index)}
            onMouseLeave={() => this.clearHover(index)}
          >
            {syllable}
          </span>
        ))}
        <div className={styles.dots}>
          {contents.map((syllable, index) => (
            <span
              className={`${styles.syllableDot} ${index === hover &&
                styles.active}`}
            />
          ))}
        </div>
      </p>
    );
  }
}

const HaikuContent = () => {
  return (
    <div>
      {Haikus.map((haiku, index) => (
        <div className={styles.haiku}>
          {haiku.map((syllables, index2) => {
            console.log(syllables);
            return <Syllables contents={syllables} key={index2} />;
          })}
        </div>
      ))}
      <p>
        Some work I will share:
        <br />
        <Link to="/posts/mimir-workforce/" className={styles.link}>
          Mimir Workforce
        </Link>{" "}
        and{" "}
        <Link to="/posts/mimir-classroom/" className={styles.link}>
          Classroom,
        </Link>
        <br />
        and{" "}
        <Link to="/posts/mimir-design-system/" className={styles.link}>
          Design System
        </Link>
      </p>
    </div>
  );
};

const NormalContent = () => (
  <p>
    Hey there, my name is Ben.
    <br />
    I'm a Digital Product Designer &amp; Developer.
    <br />I love the process of crafting delightful experiences.
    <br />
    I strive for clarity, intentionality, and a little bit of fun.
    <br />
    <br />
    Here's some work I'd like to share:
    <br />
    <Link to="/posts/mimir-design-system/" className={styles.link}>
      Mimir Design System
    </Link>
    <span className={styles.gray}> (Evolving a design system)</span>
    <br />
    <Link to="/posts/mimir-workforce/" className={styles.link}>
      Mimir Workforce
    </Link>
    <span className={styles.gray}> (Designing a product from scratch)</span>
    <br />
    <Link to="/posts/mimir-classroom/" className={styles.link}>
      Mimir Classroom
    </Link>
    <span className={styles.gray}> (Tackling design debt)</span>
  </p>
);

class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      haikuMode: false
    };
  }

  toggleHaiku = () => {
    this.setState({ haikuMode: !this.state.haikuMode });
  };

  render() {
    const { haikuMode } = this.state;
    return (
      <Layout>
        <SEO title="Home" keywords={["gatsby", "application", "react"]} />
        {haikuMode ? <HaikuContent /> : <NormalContent />}
        <p>————</p>
        <p>
          {haikuMode
            ? "Not feeling haiku-y? "
            : "This is page is best viewed in "}
          <a className={styles.link} onClick={this.toggleHaiku}>
            {haikuMode ? "Back to Normal" : "Haiku Mode"}
          </a>
        </p>
      </Layout>
    );
  }
}

export default IndexPage;
