const Haikus = [
  [
    ["Hey ", "there, ", "my ", "name's ", "Ben."],
    ["Ben", "ja", "min, ", "is ", "what ", "some ", "say."],
    ["But ", "just ", "Ben ", "is ", "good."]
  ],
  [
    ["When ", "not ", "hai", "ku", "-ing,"],
    ["Di", "gi", "tal ", "pro", "duct ", "de", "sign"],
    ["is ", "what ", "I'm ", "do", "ing."]
  ],
  [
    ["I ", "love ", "craft", "ing ", "things."],
    ["Things ", "like ", "pro", "ducts ", "and ", "some ", "cake."],
    ["De", "fi", "nit", "ely ", "cake."]
  ],
  [
    ["An", "y", "ways ", "you're ", "here,"],
    ["may", "be ", "look", "ing ", "for ", "my ", "work,"],
    ["check ", "it ", "out ", "be", "low."]
  ]
];

export default Haikus;
